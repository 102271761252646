var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-content",
    [
      _vm.auth === true
        ? [
            _vm.hidden === false
              ? [
                  _c("Header", {
                    attrs: {
                      stepPoint: _vm.stepPoint,
                      agencyName: _vm.agencyNameValue,
                      agencyTel: _vm.agencyPhoneNumberValue,
                      stepper: _vm.stepper,
                      applicationFormPage: _vm.applicationFormPage,
                      applicationFormTotalPage: _vm.applicationFormTotalPage
                    }
                  })
                ]
              : _vm._e(),
            _c("router-view", {
              attrs: { stepPoint: _vm.stepPoint },
              on: { onUpdateView: _vm.updateStepPoint }
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }