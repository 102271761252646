var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        { name: "show", rawName: "v-show", value: _vm.load, expression: "load" }
      ],
      staticClass: "loading"
    },
    [
      _c("div", { staticClass: "overlay", style: _vm.overlayPosition }, [
        _c(
          "div",
          { staticClass: "loding-box" },
          [
            _c("v-progress-circular", {
              attrs: { indeterminate: "", size: 70, width: 7, color: "loading" }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }