import {
  CONTRACTOR_CONFIRM_DATA,
  SEND_DATA,
} from '@/store/modules/temporarily/types';

export default {
  setContractorConfirmData({ commit }, contractorConfirmData) {
    commit(CONTRACTOR_CONFIRM_DATA, contractorConfirmData);
  },
  setSendData({ commit }, sendData) {
    commit(SEND_DATA, sendData);
  },
  clearContractorConfirmData: ({ commit }) =>
    commit(CONTRACTOR_CONFIRM_DATA, []),
};
