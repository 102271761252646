export default {
  FIX: [],
  NEED_UPLOAD: false,
  SELECTED_PLAN: null,
  AGENCY_PHONE_NUMBER: '',
  AGENCY_NAME: '',
  APPLICATIONFORMS: [],
  DOWNLOAD_DOC: false,
  PAGE_SUM: 0,
  PENDING_STEP: 0,
  PENDING_APPLICATIONFORMS_PAGE: 1,
  IS_AGREE_CHECKED: false,
  IS_PROCEDURE_SKIPPED: false,
  URL_TOKEN: null,
};
