<template>
    <v-btn
      v-if="agencyTel"
      :class="buttonClass"
      id="agencyTel"
      style="font-size:10px"
      :width="agencyTelWidth"
      height="28px"
      color="next"
      rounded
      outlined
      :href="'tel:' + agencyTel.replace(/-/g, '')"
    >
      <v-icon class="mr-0" size="12px" left>phone</v-icon>
      {{ $t('button.agencyTel') }}
      <template v-if="checkPc">
        （{{ agencyTel }}）
      </template>
    </v-btn>
</template>

<script>
import { contractorHeaderBreakPoint } from '@/lib/const';

export default {
  name: 'TelButton',
  props: {
    agencyTel: String,
    buttonClass:  String,
  },
  data() {
    return {
      checkPc: false,
    };
  },
  computed: {
    agencyTelWidth() {
      return this.checkPc ? '230px' : '144px';
    }
  },
  methods: {
    // resizeのたびに発火
    handleResize: function() {
      this.$set(
        this,
        'checkPc',
        window.innerWidth > contractorHeaderBreakPoint
      );
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.$set(this, 'checkPc', window.innerWidth > contractorHeaderBreakPoint);
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style>
#agencyTel > span {
  font-size: 10px !important;
}
</style>