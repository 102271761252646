import {
  URL_TOKEN,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
} from '@/store/modules/contractor/types';

export default {
  urlToken: state => state[URL_TOKEN],
  accessToken: state => state[ACCESS_TOKEN],
  refreshToken: state => state[REFRESH_TOKEN],
};
