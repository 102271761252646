import {
  FIX,
  NEED_UPLOAD,
  SELECTED_PLAN,
  AGENCY_PHONE_NUMBER,
  AGENCY_NAME,
  APPLICATIONFORMS,
  DOWNLOAD_DOC,
  PAGE_SUM,
  PENDING_STEP,
  PENDING_APPLICATIONFORMS_PAGE,
  IS_AGREE_CHECKED,
  IS_PROCEDURE_SKIPPED,
  URL_TOKEN,
} from './types';

export default {
  fix: state => state[FIX],
  needUpload: state => state[NEED_UPLOAD],
  selectedPlan: state => state[SELECTED_PLAN],
  agencyName: state => state[AGENCY_NAME],
  agencyPhoneNumber: state => state[AGENCY_PHONE_NUMBER],
  applicationForms: state => state[APPLICATIONFORMS],
  downloadDoc: state => state[DOWNLOAD_DOC],
  pageSum: state => state[PAGE_SUM],
  pendingStep: state => state[PENDING_STEP],
  pendingApplicationFormsPage: state => state[PENDING_APPLICATIONFORMS_PAGE],
  isAgreeChecked: state => state[IS_AGREE_CHECKED],
  isProcedureSkipped: state => state[IS_PROCEDURE_SKIPPED],
  urlToken: state => state[URL_TOKEN],
};
