import axios from '@/apis/axios';
import store from '@/store';
import { convertCamelToSnake } from '@/lib/api';

/**
 * 契約者認証処理
 */
export const certifications = async (token, sendData) => {
  const urlToken = token;
  if (!urlToken) return new Error('invalid token');

  const params = convertCamelToSnake(sendData);

  const response = await axios
    .get(`/contractors/certification/${urlToken}`, {
      params: { ...params },
      headers: { 'access-token': 'dummy' },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 認証コード確認
 */
export const certificationsAuth = async (token, authCode, continuationFlg) => {
  const urlToken = token;
  if (!urlToken) return new Error('invalid token');

  const params = convertCamelToSnake({ continuationFlg: continuationFlg });

  const response = await axios
    .get(`/contractors/certification/${urlToken}/${authCode}`, {
      params: { ...params },
      headers: { 'access-token': 'dummy' },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 認証コード再発行
 */
export const resendAuthCode = async (token, sendData) => {
  const urlToken = token;
  if (!urlToken) return new Error('invalid token');

  const params = convertCamelToSnake(sendData);

  const response = await axios
    .get(`/contractors/certification/${urlToken}/resend/authCode`, {
      params: { ...params },
      headers: { 'access-token': 'dummy' },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

export const tokenCheck = () =>
  new Promise(async (resolve, reject) => {
    const urlToken = store.getters['contractor/urlToken'];
    const accessToken = store.getters['contractor/accessToken'];
    const refreshToken = store.getters['contractor/refreshToken'];
    if (!urlToken || !(accessToken || refreshToken))
      return reject(new Error('invalid token'));

    const response = await axios
      .get(`/contractors/tokenCheck`, {
        headers: {
          'access-token': accessToken,
          'url-token': urlToken,
          'refresh-token': refreshToken,
        },
      })
      .catch(error => {
        return reject(error);
      });

    if (!response) return resolve([]);

    resolve(response.data);
  });
