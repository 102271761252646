<template>
  <v-content>
    <template v-if="auth === true">
      <template v-if="hidden === false">
        <Header
          :stepPoint="stepPoint"
          :agencyName="agencyNameValue"
          :agencyTel="agencyPhoneNumberValue"
          :stepper="stepper"
          :applicationFormPage="applicationFormPage"
          :applicationFormTotalPage="applicationFormTotalPage"
        />
      </template>
      <router-view :stepPoint="stepPoint" @onUpdateView="updateStepPoint" />
    </template>
  </v-content>
</template>

<script>
import Header from '@/components/organisms/contractor/Header';
import { mapGetters, mapActions } from 'vuex';
import { nonHeaderPathName, stepList, completedStepNumber } from '@/lib/const';
import { Status } from '@/lib/status';
import { tokenCheck } from '@/apis/contractor/certifications';
import {
  getContract,
  getCoordinateManagement,
} from '@/apis/contractor/contracts';

export default {
  name: 'Main',
  components: {
    Header,
  },
  data() {
    return {
      hidden: true,
      stepPoint: null,
      agencyNameValue: '',
      agencyPhoneNumberValue: '',
      stepper: [],
      applicationFormPage: null,
      applicationFormTotalPage: null,
      auth: false,
    };
  },
  methods: {
    ...mapGetters('contractor', ['urlToken', 'accessToken', 'refreshToken']),
    ...mapActions('contractor', ['setUrlToken', 'setAccessToken']),
    ...mapGetters('contract', [
      'needUpload',
      'agencyName',
      'agencyPhoneNumber',
      'pendingStep',
      'pendingApplicationFormsPage',
    ]),
    ...mapActions('contract', ['setIsProcedureSkipped']),

    // ステップ数を変更する
    updateStepPoint(stepPoint) {
      this.$set(this, 'stepPoint', stepPoint);
    },
  },
  async mounted() {},
  watch: {
    async $route(val) {
      this.$set(this, 'auth', false);
      // エラー画面及びメンテナンス画面では確認しない
      if (val.name !== 'Error' && val.name !== 'Maintenance') {
        // URLトークンが存在しない場合エラー画面に遷移
        if (!this.urlToken()) {
          if (val.query.token) {
            this.setUrlToken(val.query.token);
          } else {
            return this.$router.replace('/Error');
          }
        }

        // アクセストークンチェック
        if (val.meta.requiredAuth) {
          if (this.accessToken()) {
            // アクセストークンの確認
            const accessToken = await tokenCheck().catch(() => false);
            if (accessToken) {
              // アクセストークンの更新
              this.setAccessToken(accessToken);
            } else {
              // アクセストークンが不正な場合全ての情報を破棄してエラー画面に遷移
              localStorage.clear();
              this.$set(this, 'hidden', true);
              return this.$router.replace('/Error');
            }
          } else {
            return this.$router.replace('/');
          }

          // 閲覧エラー画面の場合
          if (val.name === 'ReadError') {
            this.$set(this, 'auth', true);
            // 以降の処理を中止する
            return;
          }

          // 案件情報の取得
          const contract = await getContract().catch(() => {});
          // 案件閲覧ステータスの取得
          const contractReadStatus = Status.getContractRead(
            contract.status,
            contract.startDate
          );

          // 案件が閲覧可でない場合
          if (!Status.isAvailable(contractReadStatus)) {
            // 閲覧エラー画面に遷移
            return this.$router.replace({ name: 'ReadError' });
          }

          // 案件ステータスが続行可でない場合
          if (
            val.name !== 'ProcedureComplete' &&
            !Status.isContinuable(contract.status)
          )
            // 最終画面に遷移
            return this.$router.replace({ name: 'ProcedureComplete' });
        }

        if (val.name == 'Login' && this.refreshToken()) {
          // トークンの確認
          const accessToken = await tokenCheck().catch(() => false);
          if (accessToken) {
            // アクセストークンの更新
            this.setAccessToken(accessToken);
            this.setIsProcedureSkipped(true);
            return this.$router.replace({ name: 'Tos' });
          }
        }

        if (
          val.name !== 'ProcedureComplete' &&
          this.pendingStep() === completedStepNumber &&
          val.meta.requiredAuth
        )
          // 確認ステータスが完了状態の場合は最終画面に遷移させる
          return this.$router.replace({ name: 'ProcedureComplete' });

        // 特定の画面では非表示にする
        this.$set(
          this,
          'hidden',
          nonHeaderPathName.contractor.indexOf(val.name) >= 0
        );
        if (this.hidden === false) {
          this.$set(
            this,
            'stepper',
            this.needUpload() ? stepList.upload : stepList.nonUpload
          );
          const step = this.stepper.find(
            step =>
              step.stepName === val.name &&
              (!step.hash || step.hash === val.hash)
          );
          if (step) {
            // 遷移先を確認済みのページまでに限定
            if (!this.pendingStep())
              return this.$router.replace({ name: 'Tos' });
            if (this.pendingStep() < step.step) {
              const pendingStep = this.stepper.find(
                step => step.step === this.pendingStep()
              );
              return this.$router.replace({ name: pendingStep.stepName });
            }

            if (step.stepName === 'ReviewContract') {
              const pageNumber = isNaN(val.params.pageNumber)
                ? 0
                : parseInt(val.params.pageNumber);
              if (
                pageNumber < 1 ||
                this.pendingApplicationFormsPage() < pageNumber
              ) {
                // 遷移先を確認済みの申込書までに限定
                return this.$router.replace({
                  name: step.stepName,
                  params: {
                    pageNumber: String(this.pendingApplicationFormsPage()),
                  },
                });
              }

              if (!this.applicationFormTotalPage) {
                const contract = await getCoordinateManagement();
                const coordinates =
                  contract.applicationForm.coordinateManagements;
                this.applicationFormTotalPage =
                  coordinates[coordinates.length - 1].screenPage;
              }
              this.$set(this, 'applicationFormPage', val.params.pageNumber);
            } else {
              this.$set(this, 'applicationFormPage', '');
            }
            this.$set(this, 'stepPoint', step.step);
          } else {
            this.$set(this, 'hidden', true);
          }
          this.agencyNameValue = this.agencyName();
          this.agencyPhoneNumberValue = this.agencyPhoneNumber();
        }
      }
      this.$set(this, 'auth', true);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/index.scss';
</style>
