import { set } from '@/utils/vuex';
import {
  CONTRACTOR_CONFIRM_DATA,
  SEND_DATA,
} from '@/store/modules/temporarily/types';

export default {
  CONTRACTOR_CONFIRM_DATA: set(CONTRACTOR_CONFIRM_DATA),
  SEND_DATA: set(SEND_DATA),
};
