<template>
  <div class="loading" v-show="load">
    <div class="overlay" :style="overlayPosition">
      <div class="loding-box">
        <v-progress-circular
          indeterminate
          :size="70"
          :width="7"
          color="loading"
        ></v-progress-circular>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data() {
    return {
      overlayPosition: {
        top: '0',
      },
    };
  },
  computed: {
    load() {
      return this.loading();
    },
  },
  methods: {
    ...mapGetters('ui', ['loading']),
  },
};
</script>

<style>
.overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: hidden;
  touch-action: none;
}

.loding-box {
  color: #fff;
  position: fixed;
  left: 50%;
  top: 50%;
  margin-left: -35px; /* 中央寄せのため要素の半分のサイズを指定 */
  _position: absolute;
  animation-name: blink;
  animation-duration: 600ms;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

@keyframes blink {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
