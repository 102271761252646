import {
  SET_DRAWER,
  TOGGLE_DRAWER,
  PRIMARY_COLOR,
  EDITING_COLOR,
  LOADING,
} from './types';

export default {
  setDrawer: ({ commit }, payload) => {
    commit(SET_DRAWER, payload);
  },
  toggleDrawer: ({ commit }) => {
    commit(TOGGLE_DRAWER);
  },
  setPrimaryColor: ({ commit }, payload) => {
    commit(PRIMARY_COLOR, payload);
  },
  setEditingColor: ({ commit }, payload) => {
    commit(EDITING_COLOR, payload);
  },
  setLoading: ({ commit }, payload) => {
    commit(LOADING, payload);
  },
};
