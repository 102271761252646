import axios from '@/apis/axios';
import { baseURL } from '@/lib/const';

/**
 * APIサーバ生存確認
 */
export const keepAlive = async () => {
  const response = await axios({ url: 'keepalive', baseURL: baseURL }).catch(error => {
    throw error.response.status;
  });
  return response.status;
};
