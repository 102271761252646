import Vue from 'vue';
import Router from 'vue-router';
import paths from './path';
import { BasePaths } from '@/lib/const';
import { keepAlive } from '@/apis/common/keepAlive';
import { HttpStatus } from '@/lib/const';

// 接続先情報の取得
const BasePath = BasePaths;
function route(path, view, name, meta) {
  return {
    name: name || view,
    path,
    meta,
    component: resovle =>
      import(`@/pages/${BasePath}/${view}.vue`).then(resovle),
  };
}

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: paths.map(path =>
    route(path.path, path.view, path.name, path.meta)
  ).concat([{ path: '*', redirect: '/' }]),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  const toPath = to.matched[0].path ? to.matched[0].path : to.path;

  const httpStatus = await keepAlive().catch(err => {
    return err;
  });
  if (to.name === 'Maintenance') {
    if (httpStatus && httpStatus !== HttpStatus.Maintenance) {
      // 通常時のメンテナンス画面へのアクセスはホーム画面にリダイレクト
      return next('/');
    }
  } else {
    if (httpStatus === HttpStatus.Maintenance) {
      // メンテナンス時はメンテナンス画面に遷移
      return next('/maintenance');
    }
  }
  next();
});

export default router;
