import i18n from '@/plugins/i18n';

export const BaseName = {
  contractor: 'contractor',
  agency: 'agency',
};

// 被保険者DNS設定
export const BasePaths = BaseName.contractor;

// API接続先
export const baseURL = process.env.VUE_APP_API_BASE_URL;

export const LineID = process.env.VUE_APP_LINE_ID;

// エラーコード
export const ErrorCode = {
  UserLoginIdExists: 'user login id exists', // ユーザーログインID既存
  ContractDeleted: 'contract deleted', // 案件削除済み
  CaseDataError: {
    dataFileNotFound: 'case data not found', // 案件データファイルがない
    invalidDataFileFormat: 'case data file is invalid', // 案件データファイルの形式が違う
    invalidData: 'data is invalid', // ファイル内容が不正
    applicationFormRecordNotFound: 'application form record not found', // 対応する申込書レコードがない
  },
};

// バリデーション用文字パターン
export const ValidatePatterns = {
  kana: {
    type: '半角ｶﾀｶﾅ',
    pattern: /^[ｦ-ﾟ]*$/,
  },
  telNumberLength: {
    type: '半角数字10〜11桁',
    pattern: /^(\d{10,11})$/,
  },
  mailAddress: {
    type: '正しいメールアドレス',
    // 全角文字を弾く
    // @の前に1文字以上・後に3文字以上文字を入力
    pattern: /^[A-Za-z0-9._+-]+@[a-zA-Z0-9-]+([.][a-zA-Z0-9-]+)+?$/,
  },
  agencyCode: {
    type: i18n.t('validation.agencyCode'),
    pattern: /^[A-Za-z0-9]{8}$/, // 半角英数字8桁
  },
  policyNumber: {
    type: '半角文字',
    pattern: /^[\x01-\x7E\uFF61-\uFF65]+$/,
  },
};

// ヘッダー非表示画面一覧
export const nonHeaderPathName = {
  contractor: ['Home', 'Login', 'Tos', 'ProcedureComplete', 'Eroor'],
};

// 手続きの流れ一覧
export const stepList = {
  nonUpload: [
    {
      text: i18n.t('label.procedureFlow.step1'),
      step: 1,
      stepName: 'BaseInfo',
      hash: '',
      params: {},
    },
    {
      text: i18n.t('label.procedureFlow.step2'),
      step: 2,
      stepName: 'ExplanImportantMatters',
      hash: '',
      params: {},
    },
    {
      text: i18n.t('label.procedureFlow.step3'),
      step: 3,
      stepName: 'ReviewContract',
      hash: '',
      params: {
        pageNumber: 1,
      },
    },
    {
      text: i18n.t('label.procedureFlow.step5'),
      step: 4,
      stepName: 'CheckNeedUpload',
      hash: '#final_review_contract',
      params: {},
    },
    {
      text: i18n.t('label.procedureFlow.step6'),
      step: 5,
      stepName: 'ProcedureComplete',
      hash: '',
      params: {},
    },
  ],
  upload: [
    {
      text: i18n.t('label.procedureFlow.step1'),
      step: 1,
      stepName: 'BaseInfo',
      hash: '',
      params: {},
    },
    {
      text: i18n.t('label.procedureFlow.step2'),
      step: 2,
      stepName: 'ExplanImportantMatters',
      hash: '',
      params: {},
    },
    {
      text: i18n.t('label.procedureFlow.step3'),
      step: 3,
      stepName: 'ReviewContract',
      hash: '',
      params: {
        pageNumber: 1,
      },
    },
    {
      text: i18n.t('label.procedureFlow.step4'),
      step: 4,
      stepName: 'CheckNeedUpload',
      hash: '#upload_required_documents',
      params: {},
    },
    {
      text: i18n.t('label.procedureFlow.step5'),
      step: 5,
      stepName: 'CheckNeedUpload',
      hash: '#final_review_contract',
      params: {},
    },
    {
      text: i18n.t('label.procedureFlow.step6'),
      step: 6,
      stepName: 'ProcedureComplete',
      hash: '',
      params: {},
    },
  ],
};

// 案件ステータス
export const ContractStatus = {
  Processing: 1, // 未送信
  Waiting: 2, // お客様確認待ち
  Concluded: 3, // 締結済
  ConcludedWithModify: 4, // 締結済（一部訂正要）
  Unconcluded: 5, // 非締結（再度提案が必要）
  Closed: 6, // 申込書送付済
  Withdrawal: 7, // 取り下げ
  Deleted: 8, // 削除
};

// 案件ステータス変更イベント
export const ContractStatusChangeEvent = {
  ConfirmConcluded: 'confirmConcluded',
  ConfirmConcludedWithModify: 'confirmConcludedWithModify',
  ConfirmWithdrawal: 'confirmWithdrawal',
  ConfirmWithdrawalWithNotify: 'confirmWithdrawalWithNotify',
  ConfirmClosed: 'confirmClosed',
  ConfirmClosedWithModify: 'confirmClosedWithModify',
  ConfirmDeleted: 'confirmDeleted',
};

// 案件ステータス編集マップ
export const ContractStatusEditMap = {
  Accept: {
    [ContractStatus.Processing]: {
      [ContractStatus.Withdrawal]: ContractStatusChangeEvent.ConfirmWithdrawal,
      [ContractStatus.Deleted]: ContractStatusChangeEvent.ConfirmDeleted,
    },
    [ContractStatus.Waiting]: {
      [ContractStatus.Withdrawal]:
        ContractStatusChangeEvent.ConfirmWithdrawalWithNotify,
    },
    [ContractStatus.Concluded]: {
      [ContractStatus.Closed]: ContractStatusChangeEvent.ConfirmClosed,
      [ContractStatus.Withdrawal]:
        ContractStatusChangeEvent.ConfirmWithdrawalWithNotify,
    },
    [ContractStatus.ConcludedWithModify]: {
      [ContractStatus.Closed]:
        ContractStatusChangeEvent.ConfirmClosedWithModify,
      [ContractStatus.Withdrawal]:
        ContractStatusChangeEvent.ConfirmWithdrawalWithNotify,
    },
    [ContractStatus.Unconcluded]: {
      [ContractStatus.Withdrawal]: ContractStatusChangeEvent.ConfirmWithdrawal,
    },
    [ContractStatus.Closed]: {
      [ContractStatus.Concluded]: ContractStatusChangeEvent.ConfirmConcluded,
      [ContractStatus.ConcludedWithModify]:
        ContractStatusChangeEvent.ConfirmConcludedWithModify,
      [ContractStatus.Withdrawal]:
        ContractStatusChangeEvent.ConfirmWithdrawalWithNotify,
    },
    [ContractStatus.Withdrawal]: {
      [ContractStatus.Deleted]: ContractStatusChangeEvent.ConfirmDeleted,
    },
    [ContractStatus.Deleted]: {},
  },
};

// 案件ステータスの表示値
export const ContractStatusDisplay = {
  [ContractStatus.Processing]: i18n.t('label.contractStatus.processing'),
  [ContractStatus.Waiting]: i18n.t('label.contractStatus.waiting'),
  [ContractStatus.Concluded]: i18n.t('label.contractStatus.concluded'),
  [ContractStatus.ConcludedWithModify]: i18n.t(
    'label.contractStatus.concludedWithModify'
  ),
  [ContractStatus.Unconcluded]: i18n.t('label.contractStatus.unconcluded'),
  [ContractStatus.Closed]: i18n.t('label.contractStatus.closed'),
  [ContractStatus.Withdrawal]: i18n.t('label.contractStatus.withdrawal'),
  [ContractStatus.Deleted]: i18n.t('label.contractStatus.deleted'),
};

// 案件閲覧ステータス
export const ContractReadStatus = {
  Available: 1, // 閲覧可
  HasNotAccess: 2, // 閲覧不可
  HasClosed: 3, // 手続き不可
  Withdrawal: 4, // 取り下げ
  Deleted: 5, // 削除
};

// 案件の有効期限
export const ContractExpiration = {
  [ContractReadStatus.HasNotAccess]: 3, // 月
  [ContractReadStatus.HasClosed]: 0, // 日
};

// 画像拡大倍率
export const imageScale = 2;

// 申込書画像スクロール位置上部マージン
export const applicationFormScrollTopMargin = 10;

// お手続き完了ステップ
export const completedStepNumber = 99;

// 必要書類アップロード最大ファイルサイズ
export const uploadLimitFileSize = 5242880;

// 必要書類アップロード画像プレビュー対象
export const imageContentType = ['image/jpeg', 'image/png', 'image/gif'];

// 文字列入力時の最大の長さ
export const MaxLength = {
  Default: 45,
  FileName: 100, // ファイル名
  MailAddress: 45, // メールアドレス
  AgencyName: 45, // 代理店名
  ChargePersonName: 45, // 担当者名
  HighLightDescription: 45, // ハイライトタイトル
  HighLightMessage: 1000, // ハイライト説明文
  AgencyComment: 500, // 代理店からのコメント(基本情報画面)
  AgencyMessage: 100, // 代理店からのメッセージ(ロゴ・メッセージ登録画面)
  PlanMessage: 100, // プランごとの必要書類関連情報
  PlanComment: 100, // プランごとの説明
  ContractNote: 150, // 案件ごとの備考
  InsuranceTypeName: 45, // 種目名
  InsuranceTypeId: 3, // 種目ID
  ImportantMatterName: 45, // 重要事項等説明書名
  InternetCovenantName: 45, // インターネット特約条項名
  ExtraFileComment: 50, // 申込書以外データコメント
  ApplicationFormName: 45, // 申込書名
  SmsMessageBody: 600, // SMS・メール送信用本文
  EmailMessageBody: 1000, // SMS・メール送信用本文
  CognitoPassword: 99, // cognito上のパスワード最大長
  SecretQuestionAnswer: 100, // 秘密の質問回答
  ModificationItem: 15, // 修正項目名の長さ
  ApplicationFormMessage: 1000, // 申込書説明文
};

// 契約者画面ヘッダ表示切替点(px)
export const contractorHeaderBreakPoint = 800;

// 送信方法
export const SenderFlg = {
  Mail: 1,
  Sms: 2,
  MailAndSms: 3,
};

// 送信方法文言
export const ReverseSenderFlg = {
  1: i18n.t('label.mail'),
  2: i18n.t('label.sms'),
  3: i18n.t('label.mailAndSms'),
};

// 認証方法
export const AuthType = {
  policyNumber: 1,
  dateOfBirth: 2,
};

// 認証識別子
export const ReverseAuthIdentifier = {
  p: AuthType.policyNumber,
  d: AuthType.dateOfBirth,
};

// 認証コード桁数
export const AuthCodeLength = 6;

// HTTPステータス
export const HttpStatus = {
  Success: 200,
  BadRequest: 400,
  Unauthorized: 401,
  Forbidden: 403,
  Locked: 423,
  InternalError: 500,
  Maintenance: 503,
};

// 最大登録可能数
export const MaxCount = {
  HighLight: 10,
  ModificationItems: 10,
  Fix: 3,
};

// 認証コード有効期限(日)
export const AuthCodeExpiryTime = 24;

// 商品名
export const ProductName = {
  Bk: 'BK', // 個人用火災総合保険
  Bl: 'BL', // 個人用火災総合保険（債務者集団扱）
};

// 保険対象
export const InsuranceObject = {
  Building: 1, // 建物
  HouseholdProperty: 3, // 家財
  OutdoorEquipment: 'A', // 屋外設備装置
  HouseholdPropertyHousedInOutdoorEquipment: 'B', // 屋外設備に収容されている家財
  HouseholdPropertyInOpenAirStorage: 'C', // 野積みの家財
  SpecificHouseholdProperty: 'D', // 特定の家財
};

// 保険対象の表示値
export const InsuranceObjectDisplay = {
  [InsuranceObject.Building]: i18n.t('label.insuranceObject.building'),
  [InsuranceObject.HouseholdProperty]: i18n.t(
    'label.insuranceObject.householdProperty'
  ),
  [InsuranceObject.OutdoorEquipment]: i18n.t(
    'label.insuranceObject.OutdoorEquipment'
  ),
  [InsuranceObject.HouseholdPropertyHousedInOutdoorEquipment]: i18n.t(
    'label.insuranceObject.HouseholdPropertyHousedInOutdoorEquipment'
  ),
  [InsuranceObject.HouseholdPropertyInOpenAirStorage]: i18n.t(
    'label.insuranceObject.HouseholdPropertyInOpenAirStorage'
  ),
  [InsuranceObject.SpecificHouseholdProperty]: i18n.t(
    'label.insuranceObject.SpecificHouseholdProperty'
  ),
};

// 初回保険料払込方法（値はindex番号）
export const FirstPayment = {
  AccountTransfer: 'accountTransferFlg', // 口座振替
  CreditCard: 'creditCardFlg', // クレジットカード払（即時）
  PaymentSlip: 'paymentSlipFlg', // 払込票払
  CashPayment: 'cashPaymentFlg', // その他払込
  DebitCard: 'debitCardFlg', // デビットカード払
};

// 初回保険料払込方法の表示値
export const FirstPaymentDisplay = {
  [FirstPayment.AccountTransfer]: i18n.t('label.firstPayment.accountTransfer'),
  [FirstPayment.CreditCard]: i18n.t('label.firstPayment.creditCard'),
  [FirstPayment.PaymentSlip]: i18n.t('label.firstPayment.paymentSlip'),
  [FirstPayment.CashPayment]: i18n.t('label.firstPayment.cashPayment'),
  [FirstPayment.DebitCard]: i18n.t('label.firstPayment.debitCard'),
};

// 基本保険期間のデータの長さ
export const BaseInsurancePeriodLength = {
  OnlyYear: 2, // 年のみの場合
  OnlyMonthOrDay: 4, // 月日のみの場合
  Both: 6, // 年と月日両方存在の場合
  MonthFlg: 'M', // 月間のフラグ
  DayFlg: 'D', // 日間のフラグ
};

// 払込方法
export const Payment = {
  Once: '0', // 一括払
  Monthly: '1', // 月払
  LongTermMonth: '2', // 長期月払
  LongTermYearly: '3', // 長期年払
  LongTermOnce: '4', // 長期一括払
  OrganizationTwelve: '5', // 団体扱12回
  OrganizationOnce: '6', // 団体扱一括
  GroupTwelve: '7', // 集団扱12回
  GroupOnce: '8', // 集団扱一括
  OrganizationLoneTermYearly: '9', // 団体長期年払
  GroupLoneTermOnce: 'A', // 集団扱長期一括払
  GroupLoneTermYearly: 'B', // 集団扱長期年
};

// 払込方法の表示値
export const PaymentDisplay = {
  [Payment.Once]: i18n.t('label.payment.once'),
  [Payment.Monthly]: i18n.t('label.payment.monthly'),
  [Payment.LongTermMonth]: i18n.t('label.payment.longTermMonth'),
  [Payment.LongTermYearly]: i18n.t('label.payment.longTermYearly'),
  [Payment.LongTermOnce]: i18n.t('label.payment.longTermOnce'),
  [Payment.OrganizationTwelve]: i18n.t('label.payment.organizationTwelve'),
  [Payment.OrganizationOnce]: i18n.t('label.payment.organizationOnce'),
  [Payment.GroupTwelve]: i18n.t('label.payment.groupTwelve'),
  [Payment.GroupOnce]: i18n.t('label.payment.groupOnce'),
  [Payment.OrganizationLoneTermYearly]: i18n.t(
    'label.payment.organizationLoneTermYearly'
  ),
  [Payment.GroupLoneTermOnce]: i18n.t('label.payment.groupLoneTermOnce'),
  [Payment.GroupLoneTermYearly]: i18n.t('label.payment.groupLoneTermYearly'),
};

// 地震保険期間の区分コード
export const EarthquakeInsurancePeriodTypeCode = {
  Same: 1, // 基本の始期から基本の終期まで
  OneYear: 2, // 基本の始期から１年間　以降、１年ごと自動継続
  FiveYears: 3, // 基本の始期から５年間　以降、５年ごと自動継続
};

// 地震保険期間の区分コードの表示値
export const EarthquakeInsurancePeriodTypeCodeDisplay = {
  [EarthquakeInsurancePeriodTypeCode.Same]: i18n.t(
    'label.earthquakeInsurancePeriodTypeCode.same'
  ),
  [EarthquakeInsurancePeriodTypeCode.OneYear]: i18n.t(
    'label.earthquakeInsurancePeriodTypeCode.oneYear'
  ),
  [EarthquakeInsurancePeriodTypeCode.FiveYears]: i18n.t(
    'label.earthquakeInsurancePeriodTypeCode.fiveYears'
  ),
};

// その他修正項目ID
export const ModificationItemOtherId = 0;

// 変換タグ
export const ConvertionTag = {
  // 太字
  Bold: {
    Start: '\\<bold\\>', // 開始タグ
    End: '\\<\\/bold\\>', // 終了タグ
  },
};

// 申込書以外データプレビュー対象拡張子一覧
export const ExtensionsAllowedInExtraPicture = ['jpeg', 'jpg', 'gif', 'png'];

// 必要書類アップロード対象コンテンツタイプ一覧
export const ExtensionsAllowedInRequiredFile = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'application/pdf',
  '.xls',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.ppt',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
];

// デフォルトの生年月日
export const DefaultDateOfBirth = '1980-01-01';
