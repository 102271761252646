import {
  FIX,
  NEED_UPLOAD,
  SELECTED_PLAN,
  AGENCY_NAME,
  AGENCY_PHONE_NUMBER,
  APPLICATIONFORMS,
  DOWNLOAD_DOC,
  PAGE_SUM,
  PENDING_STEP,
  PENDING_APPLICATIONFORMS_PAGE,
  IS_AGREE_CHECKED,
  IS_PROCEDURE_SKIPPED,
  URL_TOKEN,
} from './types';

import contractDefault from './state';

export default {
  setFix: ({ commit }, payload) => {
    commit(FIX, payload);
  },
  setNeedUpload: ({ commit }, payload) => {
    commit(NEED_UPLOAD, payload);
  },
  setSelectedPlan: ({ commit }, payload) => {
    commit(SELECTED_PLAN, payload);
  },
  setAgencyName: ({ commit }, payload) => {
    commit(AGENCY_NAME, payload);
  },
  setAgencyPhoneNumber: ({ commit }, payload) => {
    commit(AGENCY_PHONE_NUMBER, payload);
  },
  setApplicationForms: ({ commit }, payload) => {
    commit(APPLICATIONFORMS, payload);
  },
  setDownloadDoc: ({ commit }, payload) => {
    commit(DOWNLOAD_DOC, payload);
  },
  setPageSum: ({ commit }, payload) => {
    commit(PAGE_SUM, payload);
  },
  setPendingStep: ({ commit }, payload) => {
    commit(PENDING_STEP, payload);
  },
  setPendingApplicationFormsPage: ({ commit }, payload) => {
    commit(PENDING_APPLICATIONFORMS_PAGE, payload);
  },
  crearContract: ({ commit }) => {
    for (const [key, value] of Object.entries(contractDefault)) {
      commit(key, value);
    }
  },
  setIsAgreeChecked: ({ commit }, payload) => {
    commit(IS_AGREE_CHECKED, payload);
  },
  setIsProcedureSkipped: ({ commit }, payload) => {
    commit(IS_PROCEDURE_SKIPPED, payload);
  },
  setContractUrlToken({ commit }, urlToken) {
    commit(URL_TOKEN, urlToken);
  },
};
