import { set, toggle } from '@/utils/vuex';
import { DRAWER, PRIMARY_COLOR, EDITING_COLOR, LOADING } from './types';

export default {
  SET_DRAWER: set(DRAWER),
  TOGGLE_DRAWER: toggle(DRAWER),
  PRIMARY_COLOR: set(PRIMARY_COLOR),
  EDITING_COLOR: set(EDITING_COLOR),
  LOADING: set(LOADING),
};
