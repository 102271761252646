<template>
  <v-app-bar app clipped-left color="header" min-height="92px">
    <v-container class="mx-auto" style="width: 90%;">
      <v-row class="mt-6">
        <div class="stepper">
          <template v-for="item in stepper" width="auto">
            <template v-if="item.step === stepPoint">
              <!-- 現在の状態 -->
              <div
                class="step active"
                :key="item.step"
                :style="widthCalculation(item.step)"
              >
                <div class="clickable">
                  <v-container
                    class="ma-auto pa-0"
                    fill-height
                    style="height:50px;"
                  >
                    <v-row class="ma-0 pa-0">
                      <p
                        class="text-center ma-auto hearder-font-size white--text font-weight-bold"
                      >
                        {{ item.text }}
                      </p>
                    </v-row>
                    <template
                      v-if="applicationFormTotalPage && applicationFormPage"
                    >
                      <v-row class="ma-0">
                        <p
                          class="text-center ma-auto hearder-font-size white--text"
                        >
                          {{
                            applicationFormPage + '/' + applicationFormTotalPage
                          }}
                        </p>
                      </v-row>
                    </template>
                  </v-container>
                </div>
              </div>
            </template>
            <template v-else-if="item.step < stepPoint">
              <!-- 通過済みの状態 -->
              <div
                class="step done"
                :key="item.step"
                :style="widthCalculation(item.step)"
              >
                <router-link
                  :to="{
                    name: item.stepName,
                    hash: item.hash,
                    params: item.params,
                  }"
                  style="text-decoration: none;"
                >
                  <div class="clickable">
                    <v-container
                      class="ma-auto px-0"
                      fill-height
                      style="height:50px;"
                    >
                      <template v-if="checkPc">
                        <p class="text-center ma-auto">{{ item.text }}</p>
                      </template>
                      <template v-else>
                        <p class="text-center ma-auto icon">{{ item.step }}</p>
                      </template>
                    </v-container>
                  </div>
                </router-link>
              </div>
            </template>
            <template v-else>
              <!-- 未到達の状態 -->
              <div
                class="step"
                :key="item.step"
                :style="widthCalculation(item.step)"
              >
                <div class="clickable">
                  <v-container
                    class="ma-auto px-0"
                    fill-height
                    style="height:50px;"
                  >
                    <template v-if="checkPc">
                      <p class="text-center ma-auto">{{ item.text }}</p>
                    </template>
                    <template v-else>
                      <p class="text-center ma-auto icon">{{ item.step }}</p>
                    </template>
                  </v-container>
                </div>
              </div>
            </template>
          </template>
        </div>
      </v-row>
      <v-row style="height: 36px">
        <v-col class="ma-0 pa-0" align-self="center">
          <p class="ma-0 pa-0" style="font-size:10px; line-height: 100%;">
            {{ $t('label.agencyName') }} : {{ agencyName }}
          </p>
        </v-col>
        <v-col class="ma-0 my-1 pa-0">
          <TelButton v-if="agencyTel" :agencyTel="agencyTel" buttonClass="float-right" />
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { contractorHeaderBreakPoint } from '@/lib/const';
import TelButton from '@/components/molecules/contractor/TelButton';

export default {
  name: 'Header',
  props: {
    stepPoint: Number,
    agencyName: String,
    agencyTel: String,
    stepper: Array,
    applicationFormPage: String,
    applicationFormTotalPage: Number,
  },
  components: { TelButton },
  data() {
    return {
      offsetTop: 0,
      checkPc: false,
    };
  },
  computed: {
    agencyTelWidth() {
      return this.checkPc ? '230px' : '144px';
    }
  },
  methods: {
    stepText(item) {
      return item.step === this.stepPoint ? item.text : '';
    },
    widthCalculation(step) {
      const totalSize = this.stepper.length + 3;
      const width =
        step === this.stepPoint ? (100 / totalSize) * 4 : 100 / totalSize;
      return 'width:' + width + 'vw';
    },
    // resizeのたびに発火
    handleResize: function() {
      this.$set(
        this,
        'checkPc',
        window.innerWidth > contractorHeaderBreakPoint
      );
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize);
    this.$set(this, 'checkPc', window.innerWidth > contractorHeaderBreakPoint);
  },
  beforeDestroy: function() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss">
@import '@/styles/index.scss';

$bg-color: #d5ebfe;
$fg-color: #000;
$bg-done-color: #8cb8fd;
$fg-done-color: #fff;
$bg-active-color: #8cb8fd;
$fg-active-color: #fff;

$margin-left: 5px; // 矢羽の間隔
$arrow-width: 20px; // 矢羽の角度
$arrow-height: 50px;

@mixin before($color) {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -1 * $arrow-height;
  border-width: $arrow-height 0 $arrow-height $arrow-width;
  border-style: solid;
  border-color: $color transparent;
  left: -1 * $arrow-width;
}

@mixin after($color) {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -1 * $arrow-height;
  border-top: $arrow-height solid transparent;
  border-bottom: $arrow-height solid transparent;
  border-left: $arrow-width solid $color;
  right: -1 * $arrow-width;
}

@mixin step($fg-color, $bg-color) {
  .clickable {
    color: $fg-color;
    background-color: $bg-color;
    &:before {
      @include before($bg-color);
    }
    &:after {
      @include after($bg-color);
    }
  }
  &:hover .clickable {
    $bg-color: darken($bg-color, 10%);
    background-color: $bg-color;
    &:before {
      @include before($bg-color);
    }
    &:after {
      @include after($bg-color);
    }
  }
}

.stepper {
  display: flex;
  word-break: normal;
  overflow-y: hidden;

  .step {
    margin: 0;
    margin-left: $margin-left;
    margin-right: $arrow-width;
    font-size: 12px;

    &:first-child {
      margin-left: 0 !important;
      .clickable:before {
        content: none !important;
      }
    }

    .clickable {
      display: block;
      min-height: 50px;
      max-height: 50px;
      position: relative;
    }

    @include step($fg-color, $bg-color);

    &.done {
      @include step($fg-done-color, $bg-done-color);
    }

    &.active {
      @include step($fg-active-color, $bg-active-color);
      font-size: 16px;
      word-break: keep-all;
    }
  }
}

.icon {
  align-items: center;
  border-radius: 50%;
  font-size: 10px;
  justify-content: center;
  height: 15px !important;
  width: 15px !important;
  min-width: 15px !important;
  transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: white;
  color: #000;
}

// ヘッダーフロー画像が動的にサイズ調整されるため文字サイズも調整
@media screen and (max-width: 500px) {
  .hearder-font-size {
    font-size: 13px;
  }
}
@media screen and (max-width: 400px) {
  .hearder-font-size {
    font-size: 10px;
  }
}

#agencyTel > span {
  font-size: 10px !important;
}
</style>
