import axios from '@/apis/axios';
import store from '@/store';

/**
 * 契約情報取得
 */
export const getContract = async () => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const response = await axios
    .get(`/contractors/contracts`, {
      headers: { 'access-token': accessToken, 'url-token': urlToken },
    })
    .catch(error => {
      throw error.response.data;
    });

  return response.data;
};

/**
 * 基本情報取得
 */
export const getBaseInfo = async () => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const response = await axios
    .get(`/contractors/contracts/base_info`, {
      headers: { 'access-token': accessToken, 'url-token': urlToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 代理店情報取得
 */
export const getAgencyInfo = async () => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const response = await axios
    .get(`/contractors/contracts/agencies`, {
      headers: { 'access-token': accessToken, 'url-token': urlToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 必要書類情報取得
 */
export const getRequiredUploadingDocs = async () => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const response = await axios
    .get(`/contractors/contracts/required_uploading_docs`, {
      headers: { 'access-token': accessToken, 'url-token': urlToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 契約状況取得
 */
export const getStatus = async () => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const response = await axios
    .get(`/contractors/contracts/status`, {
      headers: { 'access-token': accessToken, 'url-token': urlToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 契約締結日取得
 */
export const getContractDate = async () => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const response = await axios
    .get(`/contractors/contracts/contract_date`, {
      headers: { 'access-token': accessToken, 'url-token': urlToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 修正要望取得
 */
export const getModificationRequests = async () => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const response = await axios
    .get(`/contractors/contracts/modification_requests`, {
      headers: { 'access-token': accessToken, 'url-token': urlToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 重要事項等説明書取得
 */
export const getExplanationsForImportantMatter = async () => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const response = await axios
    .get(`/contractors/contracts/explanations_for_important_matter`, {
      headers: { 'access-token': accessToken, 'url-token': urlToken },
      responseType: 'blob',
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * インターネット特約条項取得
 */
export const getInternetCovenant = async () => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const response = await axios
    .get(`/contractors/contracts/internet_covenants`, {
      headers: { 'access-token': accessToken, 'url-token': urlToken },
      responseType: 'blob',
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 申込書ダウンロード
 */
export const getApplicationForm = () =>
  new Promise(async (resolve, reject) => {
    const urlToken = store.getters['contractor/urlToken'];
    const accessToken = store.getters['contractor/accessToken'];
    if (!urlToken || !accessToken) return reject(new Error('invalid token'));

    const response = await axios.get(
      '/contractors/contracts/application_forms/downloads',
      {
        responseType: 'blob',
        dataType: 'application/pdf',
        headers: {
          'access-token': accessToken,
          'url-token': urlToken,
        },
      }
    );
    if (!response) return resolve(null);

    // typeの実態がapplication/octet-streamになっているためpdfに書き換える
    const blob = new Blob([response.data], { type: 'application/pdf' });

    resolve(blob);
  });

/**
 * 申請後申込書ダウンロード
 */
export const getCompletedApplicationForm = () =>
  new Promise(async (resolve, reject) => {
    const urlToken = store.getters['contractor/urlToken'];
    const accessToken = store.getters['contractor/accessToken'];
    if (!urlToken || !accessToken) return reject(new Error('invalid token'));

    const response = await axios.get(
      '/contractors/contracts/completed_application_forms/downloads',
      {
        responseType: 'blob',
        dataType: 'application/pdf',
        headers: {
          'access-token': accessToken,
          'url-token': urlToken,
        },
      }
    );
    if (!response) return resolve(null);

    // typeの実態がapplication/octet-streamになっているためpdfに書き換える
    const blob = new Blob([response.data], { type: 'application/pdf' });

    resolve(blob);
  });

/**
 * 座標情報取得
 */
export const getCoordinateManagement = async () => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const response = await axios
    .get(`/contractors/contracts/coordinate_management`, {
      headers: { 'access-token': accessToken, 'url-token': urlToken },
    })
    .catch(error => {
      throw error.response.data;
    });
  return response.data;
};

/**
 * 契約締結処理
 */
export const submitContract = async (selectPlanId, modification, uploads) => {
  const urlToken = store.getters['contractor/urlToken'];
  const accessToken = store.getters['contractor/accessToken'];
  if (!urlToken || !accessToken) return new Error('invalid token');
  const form = new FormData();
  form.append('selectPlanId', selectPlanId);
  form.append('modification', JSON.stringify(modification));
  uploads.forEach((upload, index) => {
    form.append('files[' + index + ']', upload);
  });
  const response = await axios
    .post(`/contractors/contracts/submit`, form, {
      headers: {
        'access-token': accessToken,
        'url-token': urlToken,
        'content-type': 'multipart/form-data',
      },
    })
    .catch(error => {
      return error;
    });
  return response.data ? response.data : response;
};

/**
 * 申込書以外データ情報取得処理
 */
export const getExtraPictureList = () =>
  new Promise(async (resolve, reject) => {
    const urlToken = store.getters['contractor/urlToken'];
    const accessToken = store.getters['contractor/accessToken'];
    if (!urlToken || !accessToken) return new Error('invalid token');

    const response = await axios.get(
      '/contractors/contracts/extra_files/list',
      {
        headers: {
          'access-token': accessToken,
          'url-token': urlToken,
        },
      }
    );

    resolve(response.data);
  });

/**
 * 申込書以外データ取得処理
 */
export const getExtraPicture = pictureId =>
  new Promise(async (resolve, reject) => {
    const urlToken = store.getters['contractor/urlToken'];
    const accessToken = store.getters['contractor/accessToken'];
    if (!urlToken || !accessToken) return new Error('invalid token');

    const params = { ['id']: pictureId };

    const response = await axios.get(
      '/contractors/contracts/extra_files/file',
      {
        params: { ...params },
        responseType: 'arraybuffer',
        headers: {
          'access-token': accessToken,
          'url-token': urlToken,
        },
      }
    );
    if (!response) return resolve(null);

    resolve(new Blob([response.data]));
  });
