import { set } from '@/utils/vuex';
import {
  URL_TOKEN,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
} from '@/store/modules/contractor/types';

export default {
  URL_TOKEN: set(URL_TOKEN),
  ACCESS_TOKEN: set(ACCESS_TOKEN),
  REFRESH_TOKEN: set(REFRESH_TOKEN),
};
