// オブジェクトであるかどうか
const isObject = target =>
  Object.prototype.toString
    .call(target)
    .slice(8, -1)
    .toLowerCase() === 'object';

// スネークケースへの変換
const convertToSnakeCase = str => {
  return str
    .split(/(?=[A-Z])/)
    .join('_')
    .toLowerCase();
};

// キャメルケースへの変換
const convertToCamelCase = str => {
  return str
    .split('_')
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join('');
};

// キャメルケースからスネークケースへの変換
export const convertCamelToSnake = target => {
  // 元の配列情報を損なわないようにディープコピーの実施
  let targetCopy = JSON.parse(JSON.stringify(target));

  if (Array.isArray(targetCopy)) {
    targetCopy.forEach(t => convertCamelToSnake(t));
  }
  if (isObject(targetCopy)) {
    Object.keys(targetCopy).forEach(key => {
      if (isObject(targetCopy[key]) || Array.isArray(targetCopy[key])) {
        convertCamelToSnake(targetCopy[key]);
      }
      if (key.split(/(?=[A-Z])/).length !== 1) {
        targetCopy[convertToSnakeCase(key)] = targetCopy[key];
        delete targetCopy[key];
      }
    });
  }
  return targetCopy;
};

// スネークケースからキャメルケースへの変換
export const convertSnakeToCamel = target => {
  // 元の配列情報を損なわないようにディープコピーの実施
  let targetCopy = JSON.parse(JSON.stringify(target));
  if (Array.isArray(targetCopy)) {
    targetCopy.forEach(t => convertSnakeToCamel(t));
  }
  if (isObject(targetCopy)) {
    Object.keys(targetCopy).forEach(key => {
      if (isObject(targetCopy[key]) || Array.isArray(targetCopy[key])) {
        convertSnakeToCamel(targetCopy[key]);
      }
      if (key.includes('_')) {
        targetCopy[convertToCamelCase(key)] = targetCopy[key];
        delete targetCopy[key];
      }
    });
  }
  return targetCopy;
};