import {
  URL_TOKEN,
  ACCESS_TOKEN,
  REFRESH_TOKEN,
} from '@/store/modules/contractor/types';

export default {
  setUrlToken({ commit }, urlToken) {
    commit(URL_TOKEN, urlToken);
  },
  setAccessToken({ commit }, accessToken) {
    commit(ACCESS_TOKEN, accessToken);
  },
  setRefreshToken({ commit }, refreshToken) {
    commit(REFRESH_TOKEN, refreshToken);
  },
  clearAccessToken: ({ commit }) => {
    commit(ACCESS_TOKEN, null);
  },
  clearRefreshTokenn: ({ commit }) => {
    commit(REFRESH_TOKEN, null);
  },
};
