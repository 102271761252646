<template>
  <v-app>
    <Loading />
    <Contractor />
  </v-app>
</template>

<script>
import Contractor from '@/pages/contractor/Main';
import Loading from '@/components/organisms/common/Loading';

export default {
  name: 'App',
  components: {
    Contractor,
    Loading,
  },
  data: () => ({}),
  methods: {},
};
</script>

<style lang="scss">
@import '@/styles/index.scss';
</style>
